import { Theme } from '@/contexts/ThemeContext';
import { subscriptionPricePeriod } from '@/utils/helpers';

export default function Services({ theme }: { theme: Theme }): JSX.Element {
  const { slug, landingPage } = theme;

  return (
    <div className="w-full bg-white">
      <div className="container mx-auto py-8 sm:px-32">
        <div className="max-w-8xl mx-auto px-6 md:px-0">
          <h3 className="text-2xl leading-8 text-center sm:text-left font-medium font-bold tracking-tight text-primary mb-3">
            Our Services
          </h3>
          {slug === 'eyesol' ? (
            <>
              <p className="text-sm">
                Licence Services offers a turnkey solution to support you
                through any type of administrative procedure in the UK with
                on-demand virtual assistants, to assist you with your Licence
                applications, to keep your Licence protected, to assist you on
                any Driving Legal issue, to offer real-time access to your
                penalty point and to benefit of exclusive discounts on many
                car-related products and services.
              </p>
              <p className="text-sm">
                We only charge <strong>£81</strong>, no hidden costs. Limited
                offer in the UK. Payment must be made by Visa or Mastercard.
              </p>
            </>
          ) : (
            <>
              <p className="text-sm pb-4">
                Try us for free for 3 days, then{' '}
                {subscriptionPricePeriod[landingPage] || '£89 every 3-months'}.
                You will benefit from the following perks: Most of the UK admin
                procedures accessible with a single click, highly available
                customer support at your disposal to answer all of your
                requests, basic legal assistance and exclusive discounts and
                deals via our loyalty program.
              </p>
              <p className="text-sm pb-4">
                Your application will be thoroughly checked for any mistakes or
                omissions before being processed by DVLA. Therefore, our
                services ensure that your application is completed successfully
                and with minimal delay.
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
